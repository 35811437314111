<template>
  <v-row>
    <v-col cols="12">
      <v-btn :to="{name: 'temples'}" color="primary" outlined icon><v-icon v-html="icons.mdiArrowLeft" /></v-btn>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-text class="text--primary">
          <v-form>
            <v-row>
              <v-col cols="12">
                <h2>Temple Details</h2>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  outlined
                  dense
                  label="Name"
                  placeholder="Name"
                  v-model="form.name"
                  :error-messages="errors.name"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-file-input
                  outlined
                  dense
                  label="Image"
                  placeholder="Image"
                  accept="image/*"
                  v-model="form.image"
                  :error-messages="errors.image"
                />
              </v-col>
              <v-col cols="12" sm="6" md="5">
                <v-text-field
                  outlined
                  dense
                  label="Address 1"
                  placeholder="Address 1"
                  v-model="form.address_1"
                  :error-messages="errors.address_1"
                />
              </v-col>
              <v-col cols="12" sm="6" md="5">
                <v-text-field
                  outlined
                  dense
                  label="Address 2"
                  placeholder="Address 2"
                  v-model="form.address_2"
                  :error-messages="errors.address_2"
                />
              </v-col>
              <v-col cols="12" sm="12" md="2">
                <v-text-field
                  outlined
                  dense
                  label="PIN Code"
                  placeholder="PIN Code"
                  v-model="form.pincode"
                  :error-messages="errors.pincode"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  outlined
                  dense
                  label="Country"
                  placeholder="Country"
                  item-text="name"
                  item-value="iso2"
                  :items="countryOptions"
                  v-model="form.country"
                  :error-messages="errors.country"
                  @change="updateStateOptions($event)"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  outlined
                  dense
                  label="State"
                  placeholder="State"
                  item-text="name"
                  item-value="iso2"
                  :items="stateOptions"
                  v-model="form.state"
                  :error-messages="errors.state"
                  @change="updateCityOptions(form.country, $event)"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-combobox
                  outlined
                  dense
                  label="City/Village"
                  placeholder="City/Village"
                  :items="cityOptions"
                  v-model="form.village_city"
                  :error-messages="errors.village_city"
                />
              </v-col>
              <v-col cols="12">
                <v-divider/>
              </v-col>
              <v-col cols="12">
                <h2>Kothari Details</h2>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  outlined
                  dense
                  label="Kothari's Name"
                  placeholder="Kothari's Name"
                  v-model="form.kothari.name"
                  :error-messages="errors['kothari.name']"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  outlined
                  dense
                  label="Kothari's Number"
                  placeholder="Kothari's Number"
                  type="number"
                  v-model="form.kothari.mobile"
                  :error-messages="errors['kothari.mobile']"
                />
              </v-col>
              <v-col cols="12">
                <v-divider/>
              </v-col>
              <v-col cols="12">
                <h2 class="float-left">Cashier Details (Optional)</h2>
                <v-btn icon outlined class="ml-3" color="primary" @click="addCashier"><v-icon>{{icons.mdiPlus}}</v-icon></v-btn>
              </v-col>
              <v-col cols="12">
                <v-row v-for="(cashier, cashierIndex) in form.cashiers" :key="cashierIndex">
                  <v-col cols="12" sm="6">
                    <v-text-field
                      outlined
                      dense
                      label="Cashier's Name"
                      placeholder="Cashier's Name"
                      v-model="form.cashiers[cashierIndex].name"
                      :error-messages="errors['cashiers.' + cashierIndex + '.name']"
                    />
                  </v-col>
                  <v-col cols="12" sm="5">
                    <v-text-field
                      label="Cashier's Number"
                      placeholder="Cashier's Number"
                      outlined
                      dense
                      type="number"
                      v-model="form.cashiers[cashierIndex].mobile"
                      :error-messages="errors['cashiers.' + cashierIndex + '.mobile']"
                    />
                  </v-col>
                  <v-col cols="12" sm="1">
                    <v-btn color="error" icon outlined @click="removeCashier(cashierIndex)"><v-icon>{{icons.mdiMinus}}</v-icon></v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-divider/>
              </v-col>
              <v-col cols="12">
                <h2>Donation Categories</h2>
              </v-col>
              <v-col v-for="(donationTypeOption, key) in donationTypeOptions" :key="key" cols="12">
                <v-row>
                  <v-col cols="12" sm="6" md="2">
                    <v-checkbox
                      class="mt-0"
                      :label="donationTypeOption.name"
                      :value="donationTypeOption.id"
                      v-model="selectedDonationTypes"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-text-field
                      v-if="form.donation_types.some(item => item.id === donationTypeOption.id)"
                      outlined
                      dense
                      type="number"
                      prefix="₹"
                      label="Per Unit"
                      placeholder="Per Unit"
                      v-model="form.donation_types[form.donation_types.findIndex(item => item.id === donationTypeOption.id)].per_unit"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="8">
                    <v-select
                      v-if="form.donation_types.some(item => item.id === donationTypeOption.id)"
                      outlined
                      dense
                      chips
                      deletable-chips
                      clearable
                      multiple
                      label="Accept At"
                      item-text="name"
                      item-value="id"
                      :items="templeOptions.filter(item => item.id !== form.id)"
                      v-model="form.donation_types[form.donation_types.findIndex(item => item.id === donationTypeOption.id)].locations"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="text-right">
                <v-btn type="reset" class="mx-2" outlined><router-link :to="{name: 'temples'}">Cancel</router-link></v-btn>
                <v-btn color="primary" @click="save">Save</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-snackbar v-model="snackbar.active" :timeout="snackbar.timeout" :color="snackbar.color">
          {{ snackbar.message }}
          <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar.active = false">Close</v-btn>
          </template>
        </v-snackbar>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import { mdiArrowLeft, mdiPlus, mdiMinus } from '@mdi/js'
  import { serialize } from 'object-to-formdata';

let templeSchema = {
  name: null,
  image: null,
  address_1: null,
  address_2: null,
  pincode: null,
  country: null,
  state: null,
  village_city: null,
  kothari: {
    name: null,
    mobile: null,
  },
  cashiers: [],
  donation_types: [],
};

export default {
  data() {
    return {
      form: {
        ...{id: null},
        ...templeSchema
      },
      errors: {...templeSchema},
      donationTypeOptions: [],
      templeOptions: [],
      countryOptions: [],
      stateOptions: [],
      cityOptions: [],
      snackbar: {
        active: false,
        message: null,
        timeout: -1,
        color: null
      },
      icons: {
        mdiArrowLeft,
        mdiPlus,
        mdiMinus,
      },
    }
  },
  computed: {
    selectedDonationTypes: {
      get() {

        return this.form.donation_types.map(item => item.id);
      },
      set(value) {

        this.form.donation_types = this.form.donation_types.filter(item => value.includes(item.id));
        value.forEach(selectedDonationTypeID => {
          if (!this.form.donation_types.some(item => item.id === selectedDonationTypeID)) {
            this.form.donation_types.push({id: selectedDonationTypeID, per_unit: this.donationTypeOptions.find(item => item.id === selectedDonationTypeID).per_unit, locations: []});
          }
        });
      }
    },
  },
  async created() {
    let countryResponse = await axiosInstanceCSC.get('/countries');
    this.countryOptions = countryResponse.data.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

    let donationTypeResponse = await axiosBackend.get(`/options/donation-types`);
    this.donationTypeOptions = donationTypeResponse.data.donation_types.filter(item => {
      return item.festival_only === false;
    });
    let templesResponse = await axiosBackend.get(`/options/temples`);
    this.templeOptions = templesResponse.data.temples;

    if (this.$route.name === 'edit-temple') {
      let templeResponse = await axiosBackend.get(`/temples/${this.$route.params.id}`);
      const currentCountry = this.countryOptions.find(item => item.name === templeResponse.data.temple.country);
      templeResponse.data.temple.country = currentCountry ? currentCountry.iso2 : null;
      await this.updateStateOptions(templeResponse.data.temple.country);
      const currentState = this.stateOptions.find(item => item.name === templeResponse.data.temple.state);
      templeResponse.data.temple.state = currentState ? currentState.iso2 : null;
      await this.updateCityOptions(templeResponse.data.temple.country, templeResponse.data.temple.state)
      this.form = {...templeResponse.data.temple, ...{kothari: templeResponse.data.temple.kothari ? templeResponse.data.temple.kothari : templeSchema.kothari , image: null}};
      this.form.donation_types = this.form.donation_types.map(donationType => {
        donationType = (({id, per_unit, locations}) => ({id, per_unit, locations}))(donationType);
        donationType.locations = donationType.locations.map(donationTypeLocation => {
          return donationTypeLocation.id;
        });
        return donationType;
      });
    }
  },
  methods: {
    save() {

      this.loading = true;
      let apiURL = '/temples';
      if (this.form.id) apiURL += '/' + this.form.id;

      let data = serialize(this.form, {
        indices: true,
        booleansAsIntegers: true
      });

      data.set('country', this.form.country ? this.countryOptions.find(item => item.iso2 === data.get('country')).name : null);
      data.set('state', this.form.state ? this.stateOptions.find(item => item.iso2 === data.get('state')).name : null);

      axiosBackend.post(apiURL, data)
        .then((response) => {

          this.$router.push({name: 'temples'});
        })
        .catch((error) => {

          if (error.response.status === 422) {

            this.errors = error.response.data.errors;
          }
          this.snackbar = {
            active: true,
            color: 'error',
            timeout: 5000,
            message: error.response.data.message,
          };
        });
    },
    updateStateOptions(ciso) {
      return new Promise((resolve, reject) => {
        axiosInstanceCSC.get(`/countries/${ciso}/states`)
          .then(response => {
            this.stateOptions = response.data.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
            resolve();
          }).catch(error => {
            resolve();
          });
      });
    },
    updateCityOptions(ciso, siso) {

      return new Promise((resolve, reject) => {

        axiosInstanceCSC.get(`/countries/${ciso}/states/${siso}/cities`)
          .then(response => {

            this.cityOptions = response.data.map(item => item.name).sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0));
            if (!this.cityOptions.includes(this.form.village_city)) this.form.village_city = null;
            resolve();
          }).catch(error => {
          resolve();
          });
      });
    },
    addCashier() {
      this.form.cashiers.push({name: null, mobile: null});
    },
    removeCashier(index) {
      this.form.cashiers.splice(index, 1);
    }
  }
}
</script>
